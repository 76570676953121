const CONSTANTS = {
    STATUS: {
        NEW: 'New',
        TARGETS_DRAFT: 'Targets Draft',
        TARGETS_REVIEW: 'Targets Review',
        TARGETS_WITHDRAWN: 'Targets Draft',
        TARGETS_REVISION: 'Targets Revision',
        TARGETS_REJECTED: 'Targets Rejected',
        TARGETS_APPROVED: 'Targets Approved',
        REVISE_REVERTED: 'Targets Approved',
        SCORES_REVIEW: 'Scores Review',
        SCORES_WITHDRAWN: 'Targets Approved',
        COMPLETED: 'Completed',
        'N/A': 'N/A',
        SCORES_REJECTED: 'Scores Rejected',
        TARGETS_EXECUTIVE_REVIEW: 'Targets Executive Review',
        SCORES_EXECUTIVE_REVIEW: 'Scores Executive Review',
    },
    STATUS_COLORS: {
        NEW: '#5C5C5C',
        TARGETS_DRAFT: '#5f6062',
        TARGETS_REVIEW: '#11637c',
        TARGETS_WITHDRAWN: '#5f6062',
        TARGETS_REVISION: '#11637c',
        TARGETS_REJECTED: '#af0e12',
        TARGETS_APPROVED: '#f37321',
        REVISE_REVERTED: '#f37321',
        SCORES_REVIEW: '#11637c',
        SCORES_WITHDRAWN: '#f37321',
        COMPLETED: '#4F7623',
        'N/A': '#5C5C5C',
        SCORES_REJECTED: '#af0e12',
        TARGETS_EXECUTIVE_REVIEW: '#11637c',
        SCORES_EXECUTIVE_REVIEW: '#11637c',
    },
    MESSAGES: {
        CONFIRM_REVISION:
            'Your target goals are approved. Revising would create a new version and route it for approval again. Would you like to continue with revision?',
        SUCCESSFUL: 'Successful',
        FAILURE: 'Something went Wrong',
        SAVED: 'Goals were saved successfully.',
        GOALS_SUBMIT_LESS_THAN: 'Saved. Please ensure the goals total up to 120 (Goals: 100, Stretch Goals: 20) ',
        GOALS_SUBMIT_GREATER_THAN: 'Saved but, Max points allowed for goals is 100. Please review before submit',
        GOALS_SUBMIT_STRETCH: 'Saved but, Max stretch goals points allowed is 20. Please review before submit',
        GOALS_NEW_VERSION: 'Version created successfully',
        GOALS_REVERT_VERSION: 'Version Reverted successfully',
        GOALS_COPY: 'Previous Quarter goals copied',
        GOALS_COPY_NOT_AVAILABLE: 'No details  available for Previous Quarter',
        WAITING_FOR_TARGET_APPROVAL: 'Targets are pending review from your manager',
        WAITING_FOR_EMPLOYEE_SUBMISSION: 'Goal targets not submitted by the employee.',
        WAITING_FOR_SCORE_APPROVAL: 'Scores are pending review from your manager',
        MORE_POINTS_WARNING: 'Avoid putting bulk goals. Try break up the points to smaller tasks',
        GOAL_SUBMIT_INVALID: 'Please fill all information',
        DELEGATION_SUBMIT_USER_NOT_AVAILABLE: 'User is not available during this period',
        DELEGATION_SUBMIT_ALREADY_EXIST: 'You already have a delegation during the chosen period',
        CYCLE_EXIST: 'Duplicate Entry',
        CYCLE_PROCESS_IS_RUNNING:
            'The cycle process is running in the background. Please wait till the process is completed',
        SAVE_REQUEST: 'Please save to proceed further or Refresh to undo.',
        MANAGER_DOES_NOT_EXIST: 'Manager Id not valid',
        DUPLICATE_USER: 'User Already exist',
        EMPLOYEE_EXIST: 'Employee Id already exist',
        WITHDRAW: 'Withdrawn Successfully',
        WAITING_FOR_TARGET_EXECUTIVE_APPROVAL: 'Targets are pending review from your executive',
        WAITING_FOR_SCORE_EXECUTIVE_APPROVAL: 'Scores are pending review from your executive',
        EMPTY_SELECTION: 'Please select at least 1 record',
        APPROVED_SUCCESSFULLY: 'Approved Successfully',
        REJECTED_SUCCESSFULLY: 'Rejected Successfully',
        DELEGATED_SUCCESSFULLY: 'Delegated Successfully',
        FEEDBACK_SAVED: 'APR feedback saved successfully',
        FEEDBACK_SUBMITTED: 'APR feedback submitted & shared successfully',
        FEEDBACK_SUBMIT_MNGR: 'APR feedback submitted to manager',
        FEEDBACK_SUBMIT_INVALID: 'Please fill all required fields',
        FEEDBACK_CLOSED: 'APR feedback submitted successfully',
        FEEDBACK_STATUS_DELEGATED: 'Feedback form currently delegated to ',
        FEEDBACK_STATUS_SHARED: 'Feedback form currently shared to ',
        FEEDBACK_STATUS_CLOSED: 'Feedback form closed for the selected year',
        ALLOWED_FILE_TYPES: 'Uploaded file type is not supported',
        INVALID_COMMENT: 'Added special character(s) is not allowed',
    },
    STRETCH_MAX: 20,
    POINTS_MAX: 100,
    FILE_UPLOAD_TYPE: '.pdf,.doc,.docx,.ppt,.pptx,.ppsx,.xlsx,.xls,.csv',
    CATEGORY_COLORS: {
        'GROW REV': '#9db93b',
        PBT10: '#00b6de',
        'NEW PROD': '#af0e12',
        OPS: '#f4c900',
        QUALITY: '#003974',
        EODB: '#00b6de',
        HR: '#9db93b',
        STRETCH: '#f37321',
        MARKETING: '#d916c3',
        'M&A': '#5f6062',
        INNOVATION: '#b75312',
        PBT20: '#7e952b',
    },
    NUMBER_OF_DAYS_FOR_NEW_ANNOUNCEMENT: 28,
    COLORS: {
        PRIMARY_COLOR: '#F05b13',
        SKELETON_COLOR: '#D6D6D7',
        SKELETON_HIGHLIGHT_COLOR: '#2F2F30',
    },
    SAMPLE_GOAL_IMPORT_FILE_PATH: `${process.env.PUBLIC_URL}/goal-template.xlsx`,
    MAX_LINES_IN_TEXTAREA: 10,
    SESSION_MAXAGE: 1000 * 60 * 120,
    SESSION_WARNING_SECONDS: 60 * 5,
};

export default CONSTANTS;
